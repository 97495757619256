import CourseFromApi from '../../models/from-api/course';
import Course from '../../models/course';
import CourseUserFromApi from '../../models/from-api/course-user';
import convertToModule from './convert-module';
import ModuleUserFromApi from '../../models/from-api/module-user';
import Module from '../../models/module';
import ModuleFromApi from '../../models/from-api/module';

const getModuleUser = (
  module: ModuleFromApi,
  courseUser?: CourseUserFromApi,
): ModuleUserFromApi => {
  if (courseUser && courseUser.courses && courseUser.courses.length) {
    const moduleUser = courseUser.courses
      .filter(c => !!c)
      .find(modUser => modUser.course_id === module.course_id);
    if (moduleUser) {
      return moduleUser;
    }
  }

  return {} as ModuleUserFromApi;
};

const convertToCourse = (
  course: CourseFromApi,
  courseUser?: CourseUserFromApi,
): Course => {
  let lastIndex: number = course.courses? (course.courses.length - 1) : 0;
  return {
    id: course.trail_id,
    thumbnailUrl: course.thumbnail,
    title: course.name,
    description: course.description,
    addedToList: course.book_mark,
    alreadyStarted: !!courseUser && !!courseUser.start_at,
    alreadyFinished: !!courseUser && !!courseUser.finish_at,
    userRate: !!courseUser && !!courseUser.review ? courseUser.review.stars : undefined,
    tags: course.tags,
    isActive: course.is_active,
    exam: course.exam
      ? {
          examId: course.exam.exam_id,
          attempts: course.exam.attempts,
          result: course.exam.result,
          finishAt: course.exam.finish_at,
        }
      : ({} as {
          examId: string;
          attempts: number;
          result: number;
          finishAt: string;
        }),
    duration: +course.duration,
    authorName:
      course.authors && course.authors.length
        ? course.authors.map(a => a.name).join(', ')
        : '',
    categoryId: course.category ? course.category.id : course.category_id || '',
    categoryName: course.category
      ? course.category.title
      : course.category_name,
    modules:
      course.courses && course.courses.length
        ? course.courses
            .sort((courseA, courseB) =>
              courseA.position > courseB.position ? 1 : -1,
            )
            .map((module, index) => {
              return convertToModule(
                module,
                course.trail_id,
                index === 0,
                getModuleUser(module, courseUser),
                module.course_id === (course.courses || [{}])[lastIndex].course_id,
              )
            })
        : ([] as Module[]),
    referenceUrl: course.video_reference || undefined,
    previewData: course.video_preview
      ? {
          contentView: course.video_preview.content_view,
          started: !!course.video_preview.start_at,
          finished: !!course.video_preview.finish_at,
        }
      : { contentView: 0, started: false, finished: false },
    average: course.review_average,
    progress: +(course.progress * 100).toFixed(2),
    creationDate: course.created_at ? new Date(course.created_at) : new Date(),
    introImage: course.info ? course.info.intro_image : undefined,
    info: course.info
};
}

export default convertToCourse;
