import React from 'react';

import Footer from '../../Footer';
import Header from '../../Header';
import SearchInput from '../../Inputs/SearchInput';

import { ContentContainer, LayoutContainer, SearchInputContent } from './style';

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <LayoutContainer>
      <Header />

      <SearchInputContent>
        <SearchInput />
      </SearchInputContent>

      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </LayoutContainer>
  );
};

export default DefaultLayout;
