import styled from 'styled-components';

import { Container } from '../../Container';

const LayoutContainer = styled(Container)`
  @media (max-width: 768px) {
    margin-bottom: 72px;
  }
`;

const SearchInputContent = styled.div`
  width: 100%;
  padding: 24px 15px 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
`;

export { ContentContainer, LayoutContainer, SearchInputContent };
