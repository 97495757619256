import React, { useState } from 'react';
import { BsBookmark } from 'react-icons/bs';
import { FiHome, FiLogOut, FiPlay, FiSearch, FiX } from 'react-icons/fi';
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';
import {
  IoCheckmarkCircleOutline, IoPersonOutline,
  IoPlayCircleOutline, IoRibbonOutline
} from 'react-icons/io5';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CameraIcon from '../../assets/icons/CameraIcon';
import { RiCalendarCheckFill } from 'react-icons/ri';
import CalendarIcon from '../../assets/icons/CalendarIcon';
import MonitorIcon from '../../assets/icons/MonitorIcon';
import HeaderLogo from '../../assets/logoleve.svg';
import LogoLeveResp from '../../assets/logoleveresp.png';
import PerfilAvatar from '../../assets/perfil.png';
import { useAuth } from '../../context/AuthContext';
import useSearch from '../../hooks/useSearch';
import { User } from '../../models/from-api/user';
import SearchInput from '../Inputs/SearchInput';
import Menu from '../Menu';
import ProfileDropdown from './components/ProfileDropdown';
import {
  HeaderContainer,
  HeaderContent, HeaderUtilities, ProfileContainer, SearchInputContainer
} from './style';

const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { logout } = useAuth()

  const [openMenuBar, setOpenMenuBar] = useState(false);
  const [user] = useState<User>(
    JSON.parse(localStorage.getItem('leve-educacao-user') as string),
  );
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [search, setSearch] = useState('');
  const { setSearchParam } = useSearch();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchParam(search);

    if (location.pathname !== '/search') {
      history.push('/search');
    }
  };

  return (
    <HeaderContainer>
      <HeaderContent className="content">
        <button
          type="button"
          className="btnOpenMenuBar"
          onClick={() => setOpenMenuBar(true)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        {openMenuBar && (
          <div className="menuBar">
            <button
              type="button"
              className="btnCloseMenu"
              onClick={() => setOpenMenuBar(false)}
            >
              <IoMdClose size={22} color="#1d2680" />
            </button>
            <img
              src={LogoLeveResp}
              alt="Logo Leve"
              className="logoleveresp"
              sizes="min-width: 768px"
            />
            <li>
              <Link
                to="#"
                className="menuList"
                onClick={() => setShowSubMenu(!showSubMenu)}
              >
                <IoPersonOutline />
                &nbsp; Meu Perfil &nbsp;
                <IoIosArrowDown />
              </Link>
            </li>
            {showSubMenu && (
              <>
                <ul className="subMenu">
                  <li>
                    <Link
                      to="/profile-initial"
                      className="subMenuList"
                      onClick={() => setOpenMenuBar(false)}
                    >
                      <IoPersonOutline />
                      &nbsp; Meus dados
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ongoing-courses"
                      className="subMenuList"
                      onClick={() => setOpenMenuBar(false)}
                    >
                      <IoPlayCircleOutline />
                      &nbsp; Em andamento
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/finished-courses"
                      className="subMenuList"
                      onClick={() => setOpenMenuBar(false)}
                    >
                      <IoCheckmarkCircleOutline />
                      &nbsp; Em Concluídos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/favorite-courses"
                      className="subMenuList"
                      onClick={() => setOpenMenuBar(false)}
                    >
                      <BsBookmark />
                      &nbsp; Favoritos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/certificates"
                      className="subMenuList"
                      onClick={() => setOpenMenuBar(false)}
                    >
                      <IoRibbonOutline />
                      &nbsp; Certificados
                    </Link>
                  </li>
                </ul>
              </>
            )}
            <li>
              <Link
                to="/home"
                className="menuList"
                onClick={() => setOpenMenuBar(false)}
              >
                <FiHome size={20} />
                &nbsp; Início
              </Link>
            </li>
            <li>
              <Link
                to="/course-categories"
                className="menuList"
                onClick={() => setOpenMenuBar(false)}
              >
                <MonitorIcon />
                &nbsp; Cursos
              </Link>
            </li>
            <li>
              <Link
                to="/trails"
                className="menuList"
                onClick={() => setOpenMenuBar(false)}
              >
                <FiPlay size={20} />
                &nbsp; Trilhas
              </Link>
            </li>
            <li>
              <Link
                to="/events"
                className="menuList"
                onClick={() => setOpenMenuBar(false)}
              >
                <RiCalendarCheckFill />
                &nbsp; Eventos
              </Link>
            </li>
            <br />
            <br />
            <li>
              <Link to="/signIn" className="menuListExit" onClick={logout}>
                <FiLogOut size={16} color="#1d2680" /> Sair
              </Link>
            </li>
          </div>
        )}

        <Link to="/home" className="logo">
          <img src={HeaderLogo} alt="logo" />
        </Link>

        <button className="searchIco" onClick={() => setShowSearchInput(true)}>
          <FiSearch size={20} color="#0D008C" />
        </button>

        {showSearchInput && (
          <SearchInputContainer onSubmit={e => handleSearch(e)}>
            <FiSearch size={24} color="#0D008C" />
            <input
              type="text"
              placeholder="Digite aqui sua pesquisa..."
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <button type="button" onClick={() => setShowSearchInput(false)}>
              <FiX size={26} color="#0D008C" />
            </button>
          </SearchInputContainer>
        )}

        <Menu />
        <HeaderUtilities>
          <SearchInput />

          <ProfileContainer
            className={`
          ${location.pathname === '/profile' ||
                location.pathname === '/favorite-courses' ||
                location.pathname === '/ongoing-courses' ||
                location.pathname === '/finished-courses' ||
                location.pathname === '/certificates'
                ? 'active'
                : ''
              }
        `}
          >
            <button onClick={() => history.push('/ongoing-courses')}>
              <span>Perfil</span>
              <img
                src={
                  user?.info && user?.info.avatar
                    ? user?.info.avatar
                    : PerfilAvatar
                }
                alt="Perfil"
              />
            </button>

            <ProfileDropdown />
          </ProfileContainer>
        </HeaderUtilities>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
