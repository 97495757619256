import { lazy } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthLayout from '../components/Layouts/AuthLayout';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import { useAuth } from '../context/AuthContext';


// Lazy importing components
const SignIn = lazy(() => import('../pages/SignIn'));
const Home = lazy(() => import('../pages/Home'));
const Courses = lazy(() => import('../pages/Courses'));
const UserData = lazy(() => import('../pages/Profile/Routes/UserData'));
const ProfileInitial = lazy(() => import('../pages/Profile/Routes/UserData/components/ProfileInitial'));
const OngoingCourses = lazy(() => import('../pages/Profile/Routes/OngoingCourses'));
const FavoriteCourses = lazy(() => import('../pages/Profile/Routes/FavoriteCourses'));
const FinishedCourses = lazy(() => import('../pages/Profile/Routes/FinishedCourses'));
const Certificates = lazy(() => import('../pages/Profile/Routes/Certificate'));
const ViewCertificates = lazy(() => import('../pages/ViewCertificate'));
const Events = lazy(() => import('../pages/Events'));
const FAQ = lazy(() => import('../pages/FAQ'));
const Search = lazy(() => import('../pages/Search'));
const CourseCategories = lazy(() => import('../pages/CourseCategories'));
const Course = lazy(() => import('../pages/Course'));
const Trails = lazy(() => import('../pages/Trails'));
const Exam = lazy(() => import('../pages/Exam'));
const NotFound = lazy(() => import('../pages/NotFound'));

const AuthRequired = (props: RouteProps) => {
  const { user } = useAuth();

  if(!user) {
    return <Redirect to="/" />
  }
  
  return (
    <DefaultLayout>
      <Route {...props} /> 
    </DefaultLayout>
  )
};

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <AuthLayout>
          <SignIn />
        </AuthLayout>
      </Route>
        <AuthRequired path="/home" component={Home} />
        <AuthRequired path="/profile" component={UserData} />
        <AuthRequired path="/profile-initial" component={ProfileInitial} />
        <AuthRequired path="/ongoing-courses" component={OngoingCourses} />
        <AuthRequired path="/favorite-courses" component={FavoriteCourses} />
        <AuthRequired path="/finished-courses" component={FinishedCourses} />
        <AuthRequired path="/certificates" component={Certificates} />
        <Route path="/certificate/:certificateId" exact component={ViewCertificates} />
        <AuthRequired path="/events" exact component={Events} />
        <AuthRequired path="/faq" exact component={FAQ} />
        <AuthRequired path="/search" component={Search} />
        <AuthRequired path="/course-categories" exact component={CourseCategories} />
        <AuthRequired path="/courses/:categoryId" exact component={Courses} />
        <AuthRequired path="/course/:courseId" exact component={Course} />
        <AuthRequired path="/course/:courseId/module/:moduleId/lesson/:lessonId" exact component={Course} />
        <AuthRequired path="/trails" component={Trails} exact />
        <AuthRequired path="/trails/:categoryId" exact component={Trails} />
        <AuthRequired path="/exam/:examId/course/:courseId/module/:moduleId" exact component={Exam} />
        <AuthRequired component={NotFound} />
    </Switch>
  );
};
