import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background-color: var(--bg);
  width: 100vw;
  height: 105px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    height: 72px;
    position: grid;
    bottom: 0;
    z-index: 5;
    border-bottom: 1px solid var(--default-gray);
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  a {
    img {
      width: 108px;
      height: 67px;
    }
  }

  .btnOpenMenuBar {
    display: none;
  }

  .searchIco {
    display: none;
  }

  .menuBar {
    display: none;
  }

  @media (max-width: 992px) {
    a {
      img {
        display: flex;
        width: 60px;
        height: 40px;
        margin-bottom: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 140vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btnOpenMenuBar {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      background: var(--bg);
      border: none;
      width: 40px;
      height: 20px;
      top: 5px;
      right: 10px;
      border-radius: 3px;
      z-index: 15;
    }

    span {
      display: block;
      width: 25px;
      height: 3px;
      margin-bottom: 5px;
      position: relative;
      background: var(--leve-color);
      border-radius: 3px;
      z-index: 16;
    }

    .menuBar {
      display: grid;
      flex-direction: column;
      position: fixed;
      width: 248px;
      padding: 25px 15px 50px 15px;
      top: 0;
      left: 0;
      bottom: 0;
      background: var(--bg);
      z-index: 100;

      li {
        padding: 2px 15px 10px;
        font-size: var(--font-large);
        line-height: 15px;
        margin-bottom: 15px;
        color: var(--leve-color);
        border-bottom: 1px solid var(--default-gray);
        list-style: none;

        a {
          display: flex;
          align-items: center;
        }

        .menuList {
          text-decoration: none;
          color: var(--leve-color);
          font-size: var(--font-default);
          font-weight: bold;
        }

        .subMenuList {
          text-decoration: none;
          color: var(--leve-color);
          font-size: var(--font-default);
        }

        .menuListExit {
          text-decoration: none;
          color: var(--leve-color);
          font-size: var(--font-default);
          font-weight: bold;
          position: static;
        }

        svg {
          color: var(--leve-color);
          top: 10px;

          &:last-of-type {
            width: 20px;
            height: 20px;
          }
        }
      }

      .subMenu {
        padding-left: 30px;
      }
    }

    .logoleveresp {
      padding: 5px 15px 30px;
    }

    .btnCloseMenu {
      position: relative;
      width: 20px;
      left: 185px;
      top: 50px;
      background: none;
      border: none;
    }

    .searchIco {
      display: flex;
      background: none;
      border: none;
    }
  }
`;

export const HeaderUtilities = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  height: 100%;
  width: 40%;

  @media (max-width: 768px) {
    width: unset;

    form.search-input {
      display: none;
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    width: 50%;
  }
`;

export const Menu = styled.header`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  margin-left: 75px;
  transition: all 0.3s ease-in-out;
  border-width: 0%;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: none;
  }

  &:after {
    content: '';
    width: 0%;
    opacity: 0;
    height: 7px;
    bottom: 0;
    left: 0;
    position: absolute;
    background: var(--secondary-color);
  }

  &.active {
    span {
      font-weight: 500;
    }

    &:after {
      width: 100%;
      opacity: 1;
    }
  }

  span {
    font-size: var(--font-large);
    font-weight: bold;
    color: var(--secondary-color);
    margin-right: 10px;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  nav {
    visibility: hidden;
    transition: visibility 0.1s;
  }

  &:hover {
    nav {
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    display: none;

    button {
      display: none;
    }

    span {
      display: none;
    }
  }
`;

export const SearchInputContainer = styled.form`
  position: absolute;
  background: #fff;
  z-index: 999;
  height: 70px;
  width: 92%;
  padding: 0 12px;

  display: flex;
  align-items: center;

  button {
    border: none;
    background: none;
  }

  input {
    border: none;
    height: 32px;
    margin: 0 auto 0 16px;
    font-size: 16px;
    color: #909599;
    outline: none;

    &::placeholder {
      font-size: 16px;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
