import React, { Suspense } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from './context/AuthContext';
import Loading from './components/Loading';

const root = document.getElementById('root');

render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  root,
);
