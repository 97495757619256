import Category from "../../models/category";
import CategoryFromApi from "../../models/from-api/category";

const convertToCategory = (category: CategoryFromApi): Category => {
  return {
    id: category.category_id,
    title: category.title,
    description: category.description,
    active: category.is_active,
    logo: category.logo,
    info: category.info,
  }
}

export default convertToCategory;