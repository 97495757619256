import {
  getExamAttemptData,
  getExamData,
  getPreviousAnswers,
  getQuestion,
} from './../services/exam/index';
import Course from '../models/course';

const checkIfContentsIsLocked = async (course: Course) => {
  if (course.modules && course.modules.length) {
    const allLessons = course.modules
      .map(module => module.lessons || [])
      .flat();

    const allModules = course.modules;

    let lessonsIndex = 0;
    let modulesIndex = 0;
    for (let module of allModules) {
      for (let lesson of module.lessons) {
        if (!course.alreadyStarted) {
          lesson.isLocked = true;
        } else if (course.alreadyStarted && lessonsIndex === 0) {
          lesson.isLocked = false;
        } else {
          const previousLessons = [...allLessons].splice(0, lessonsIndex);
          const previousModules = [...allModules].splice(0, modulesIndex);

          const teste = await Promise.all(
            previousModules.map(async module => {
              if (module.exam) {
                const exam = await getExamData(module.exam);
                const attempts = await getExamAttemptData(
                  module.id,
                  exam.exam_id,
                  course.id
                );
                const orderedAttempts = attempts!
                  .filter(att => !!att.final_date)
                  .sort((att1, att2) =>
                    new Date(att1.final_date).getTime() >
                    new Date(att2.final_date).getTime()
                      ? 1
                      : -1,
                  );

                const lastAttempt = orderedAttempts.pop();

                if (!lastAttempt?.final_date) {
                  return true;
                }

                const answers = await getPreviousAnswers(
                  lastAttempt!.exam_user_id,
                );

                const questions = [];
                for (let answer of answers) {
                  const question = await getQuestion(answer.question_id);
                  questions.push(question);
                }

                return (
                  (lastAttempt!.result / questions.length) * 100 < +exam.average
                );
              }
              return false;
            }),
          );
          const wasNotApprovedOnSomeOfLastExams = teste.some(
            notApproved => notApproved,
          );

          if (
            !lesson.alreadyFinished &&
            (!previousLessons.length ||
              wasNotApprovedOnSomeOfLastExams ||
              previousLessons.some(
                previousLesson => !previousLesson.alreadyFinished,
              ))
          ) {
            lesson.isLocked = true;
          }
        }

        lessonsIndex++;
      }
      modulesIndex++;
    }
  }
};

export default checkIfContentsIsLocked;
