import React from 'react';

const MonitorIcon: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"      
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 16.5L17.875 16.5C18.6344 16.5 19.25 15.8844 19.25 15.125V5.5C19.25 4.74061 18.6344 4.125 17.875 4.125L4.125 4.125C3.36561 4.125 2.75 4.74061 2.75 5.5V15.125C2.75 15.8844 3.36561 16.5 4.125 16.5Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 19.25H8.25"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 10.3125L9.625 7.5625V13.0625L13.75 10.3125Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MonitorIcon;
