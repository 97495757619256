import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import {
  IoPersonOutline,
  IoPlayCircleOutline,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5';
import { BsBookmark } from 'react-icons/bs';
import {  FiAward } from 'react-icons/fi';
import { Container, ProfileLinks, ExitButton } from './style';
import { useAuth } from '../../../../context/AuthContext'

const ProfileDropdown: React.FC = () => {
  const { logout } = useAuth()

  return (
    <Container>
      <ProfileLinks to="/profile">
        <IoPersonOutline /> Meus dados
      </ProfileLinks>

      <ProfileLinks to="/ongoing-courses">
        <IoPlayCircleOutline /> Em andamento
      </ProfileLinks>

      <ProfileLinks
        to="/finished-courses"
      >
        <IoCheckmarkCircleOutline /> Concluídos
      </ProfileLinks>

      <ProfileLinks to="/favorite-courses">
        <BsBookmark /> Favoritos
      </ProfileLinks>

      <ProfileLinks to="/certificates">
        <FiAward /> Certificados
      </ProfileLinks>

      <ExitButton type="button" onClick={logout}>
        <FiLogOut size={16} color="#0D008C" /> Sair
      </ExitButton>
    </Container>
  );
};

export default ProfileDropdown;
