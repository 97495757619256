import convertToCategory from '../../helpers/converters/convert-category';
import Category from '../../models/category';
import httpClient from '../../http-client';
import CategoryFromApi from '../../models/from-api/category';

const getCategories = async (params?: any): Promise<Category[]> => {
  const categories = (
    await httpClient.get(`${process.env.REACT_APP_API_BASE_URL}/categories`, {
      params,
    })
  ).data;

  let convertedCategories = [] as Category[];
  if (categories && categories.length) {
    convertedCategories = categories.map(convertToCategory);
  }

  return convertedCategories;
};

const getCategory = async (categoryId: string) => {
  const category = (
    await httpClient.get<CategoryFromApi>(`/categories/${categoryId}`)
  )?.data;

  let convertedCategory = {} as Category;
  if (category) {
    convertedCategory = convertToCategory(category);
  }

  return convertedCategory;
};

export { getCategory, getCategories };
