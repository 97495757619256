import { useEffect, createContext, useContext, useState, useMemo } from 'react';
import { getCategories as getCategoriesService } from '../services/categories';
import { useAuth } from './AuthContext';

import type { ReactNode } from 'react';
import type Category from '../models/category';

type CategoriesContext = {
  categories: Category[] | undefined;
};

type ProviderProps = {
  children: ReactNode;
};

export const Context = createContext({} as CategoriesContext);

export default function CategoriesProvider({ children }: ProviderProps){
  const [categories, setCategories] = useState<Category[]>()
  const { user } = useAuth();
  
  useEffect(() => {
    (async () => {
      if(user) {
        const localCategories = await getCategoriesService({ is_active: true });
        setCategories(localCategories)
      }
    })()
  }, [user])

  const value = useMemo(() => ({ categories }), [categories])

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export function useCategories() {
  const { categories } = useContext(Context);
  return categories ?? {} as Category[];
};
