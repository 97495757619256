import Content from './components/Content';
import { SearchProvider } from './context/search';
import CategoryProvider from './context/CategoryContext';

import './slick.css';

export default function App() {
  return (
    <CategoryProvider>
      <SearchProvider>
        <Content />
      </SearchProvider>
    </CategoryProvider>
  );
};
