import httpClient from '../../http-client';

interface IFormSignIn {
  registration: string;
  password: string;
  company?: string;
}

const signIn = async ({
  registration,
  password,
  company
}: IFormSignIn) => {
    const response = (await httpClient
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        registration,
        password,
        company
      })).data;
    return response
};

export { signIn };
